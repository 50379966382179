(() => {
  stimulus.register("swiper", class extends Stimulus.Controller {
    static targets = ["container", "next", "prev"];

    init(event) {
      event.preventDefault();

      const { key } = event.detail;

      this.swiper = new Swiper(this.containerTarget, {
        navigation: {
          nextEl: this.nextTarget,
          prevEl: this.prevTarget,
        },
        keyboard: {
          enabled: true
        },
      });

      const activeSlide = this.swiper.slides.findIndex(function(slide, index) {
        if (slide.dataset.key == key) {
          return index;
        }
      });

      this.swiper.slideTo(activeSlide, 0);
    }

    destroy(event) {
      event.preventDefault();
      this.swiper.destroy();
    }
  });
})();
